import { API_URL } from "./config";

export async function getBettingTableColor(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-color/${page}/${pageSize}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getBettingOneMinuteTableColor(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-color-one/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getBettingFiveMinutesTableColor(
  authToken,
  page,
  pageSize
) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/dice-roll-color-five/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}
