import { Fragment, useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import Slider from "react-input-slider";
import { Checkbox } from "antd";
import NumberFormat from "../../utilities/NumberFormat";
import { Transition, Dialog } from "@headlessui/react";

function BettingModel({
  isModalVisible,
  isDisabled,
  betFind,
  setIsModalVisible,
  setFinder,
  handleOk,
  setBetFind,
}) {
  const [state, setState] = useState(1);
  const [radio, setRadio] = useState(1);
  const [checkBox, setCheckBox] = useState(false);
  const [error, setError] = useState("");

  const onValueChange = (event) => {
    setRadio(event.target.value);
  };
  function onChange(e) {
    setCheckBox(e.target.checked);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setCheckBox(false);
    setRadio(1);
    setState(1);
    setError("");
    setFinder(0);
    setBetFind("");
  };

  useEffect(() => {
    setFinder(state * radio);
  }, [state, radio])

  return (
    <div>
      <Transition.Root show={isModalVisible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleCancel()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex  items-start mt-16
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex items-center justify-between py-2  ">
                      <h1 className="text-left font-medium text-lg">
                        Hit your amount on =
                        <span className="uppercase font-bold text-blue-600">
                          {" "}
                          {betFind}
                        </span>
                      </h1>
                      <IoIosCloseCircle
                        onClick={handleCancel}
                        color="#000"
                        className="cursor-pointer text-2xl"
                      />
                    </div>

                    <div className="select-none">
                      <div className="mt-1 text-center">
                        <div className="w-full h-px bg-slate-500 mt-1" />
                        <div className="mt-1">
                          <h1 className="text-left font-medium">Amount</h1>
                          <div className="flex space-x-3 justify-center p-5">
                            <div className="radio">
                              <label
                                className={`${radio === "10"
                                  ? "border-green-500 font-semibold"
                                  : "border-gray-500 font-normal"
                                  }
                border-2 py-1 px-2 rounded-lg cursor-pointer`}
                              >
                                <input
                                  type="radio"
                                  value="10"
                                  className="hidden"
                                  checked={radio === "10"}
                                  onChange={onValueChange}
                                />
                                {NumberFormat.currencyFormat(10)}
                              </label>
                            </div>
                            <div className="radio">
                              <label
                                className={`${radio === "100"
                                  ? "border-green-500 font-semibold"
                                  : "border-gray-500 font-normal"
                                  }
              border-2 py-1 px-2 rounded-lg cursor-pointer`}
                              >
                                <input
                                  type="radio"
                                  value="100"
                                  className="hidden"
                                  checked={radio === "100"}
                                  onChange={onValueChange}
                                />
                                {NumberFormat.currencyFormat(100)}
                              </label>
                            </div>
                            <div className="radio">
                              <label
                                className={`${radio === "1000"
                                  ? "border-green-500 font-semibold"
                                  : "border-gray-500 font-normal"
                                  }
              border-2 py-1 px-2 rounded-lg cursor-pointer`}
                              >
                                <input
                                  type="radio"
                                  value="1000"
                                  className="hidden"
                                  checked={radio === "1000"}
                                  onChange={onValueChange}
                                />
                                {NumberFormat.currencyFormat(1000)}
                              </label>
                            </div>
                            <div className="radio">
                              <label
                                className={`${radio === "10000"
                                  ? "border-green-500 font-semibold"
                                  : "border-gray-500 font-normal"
                                  }
              border-2 py-1 px-2 rounded-lg cursor-pointer`}
                              >
                                <input
                                  type="radio"
                                  value="10000"
                                  className="hidden"
                                  checked={radio === "10000"}
                                  onChange={onValueChange}
                                />
                                {NumberFormat.currencyFormat(10000)}
                              </label>
                            </div>
                          </div>

                          <div className="py-1">
                            <h1 className="text-left mb-1 font-medium">
                              Slide to increase your amount
                            </h1>
                            <Slider
                              styles={{
                                track: {
                                  backgroundColor: "blue",
                                },
                                active: {
                                  backgroundColor: "#00cc00",
                                },
                                thumb: {
                                  width: 20,
                                  height: 20,
                                },
                                disabled: {
                                  opacity: 0.5,
                                },
                              }}
                              axis="x"
                              x={state}
                              xmin={1}
                              xmax={10}
                              onChange={(event) => setState(event.x)}
                            />
                          </div>

                          <div className="flex font-medium text-gray-700 my-3 mb-4">
                            <Checkbox
                              checked={checkBox ? true : false}
                              onChange={onChange}
                            >
                              Agree to hit the amount
                            </Checkbox>
                          </div>
                          {error ? (
                            <p className="text-sm text-red-600 mt-1">{error}</p>
                          ) : null}
                          <div className="w-full h-px bg-slate-400 mt-1" />
                          <div className="flex flex-row justify-between items-center my-3 mr-2">
                            <h1 className="text-left text-lg font-medium">
                              amount :{" "}
                              {radio === 1
                                ? 0
                                : NumberFormat.currencyFormat(state * radio)}
                            </h1>

                            <div className="flex justify-end space-x-5">
                              <button
                                className="w-16 text-center py-2 rounded-md
                  active:bg-gray-200 bg-gray-100 font-bold
                 text-gray-600 hover:bg-gray-200
                  focus:outline-none my-1"
                                id="open-btn"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                              <button
                                className="w-16 text-center py-2 rounded-md
                   active:bg-gray-700 bg-gray-900 font-bold
                  text-white hover:bg-gray-700
                   focus:outline-none my-1"
                                id="open-btn"
                                onClick={() =>
                                  isDisabled
                                    ? null
                                    : handleOk(
                                      checkBox,
                                      radio,
                                      setCheckBox,
                                      setRadio,
                                      setState,
                                      setError
                                    )
                                }
                              >
                                Hit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default BettingModel;
