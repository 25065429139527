import { API_URL } from "./config";

export async function getDebitTable(authToken, userId, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/debit/user/${userId}/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export const postDebit = async (authToken, data) => {
  // console.log(authToken, JSON.stringify(data));
  const res = await fetch(`${API_URL}/debit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error during the debit amount");
  }
  return await res.json();
};
