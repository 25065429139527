import { API_URL } from "./config";

export const sendEmail = async (authToken, data) => {
  const res = await fetch(`${API_URL}/contact`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error during the add account.");
  }
  return await res.json();
};