import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import NumberFormat from "../../utilities/NumberFormat";
import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";

function AddAmountModel({
  radio,
  amount,
  setAmount,
  onValueChange,
  onHandleAddAmount,
  onHandleCancel,
  isModalCreditVisible,
}) {
  const [error, setError] = useState("");

  return (
    <div>
      <Transition.Root show={isModalCreditVisible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => onHandleCancel(setError)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex  items-start mt-16
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left px-2 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="mt-1 text-center">
                    <div className="flex items-center justify-between py-2 px-5">
                      <h1 className="text-left font-medium text-lg">
                        Add Amount
                      </h1>
                      <IoIosCloseCircle
                        onClick={() => onHandleCancel(setError)}
                        color="#000"
                        className="cursor-pointer text-2xl"
                      />
                    </div>
                    <div className="w-full h-px bg-slate-500 mt-1" />
                    <div className="flex space-x-5 justify-center p-5">
                      <div className="radio">
                        <label
                          className={`${radio === "100"
                              ? "border-green-500 font-semibold"
                              : "border-gray-500 font-normal"
                            }
                border-2 py-1 px-2 rounded-lg cursor-pointer`}
                        >
                          <input
                            type="radio"
                            value="100"
                            className="hidden"
                            checked={radio === "100"}
                            onChange={onValueChange}
                          />
                          {NumberFormat.currencyFormat(100)}
                        </label>
                      </div>
                      <div className="radio">
                        <label
                          className={`${radio === "500"
                              ? "border-green-500 font-semibold"
                              : "border-gray-500 font-normal"
                            }
                border-2 py-1 px-2 rounded-lg cursor-pointer`}
                        >
                          <input
                            type="radio"
                            value="500"
                            className="hidden"
                            checked={radio === "500"}
                            onChange={onValueChange}
                          />
                          {NumberFormat.currencyFormat(500)}
                        </label>
                      </div>
                      <div className="radio">
                        <label
                          className={`${radio === "1000"
                              ? "border-green-500 font-semibold"
                              : "border-gray-500 font-normal"
                            }
                border-2 py-1 px-2 rounded-lg cursor-pointer`}
                        >
                          <input
                            type="radio"
                            value="1000"
                            className="hidden"
                            checked={radio === "1000"}
                            onChange={onValueChange}
                          />
                          {NumberFormat.currencyFormat(1000)}
                        </label>
                      </div>
                      <div className="radio">
                        <label
                          className={`${radio === "10000"
                              ? "border-green-500 font-semibold"
                              : "border-gray-500 font-normal"
                            }
              border-2 py-1 px-2 rounded-lg cursor-pointer`}
                        >
                          <input
                            type="radio"
                            value="10000"
                            className="hidden"
                            checked={radio === "10000"}
                            onChange={onValueChange}
                          />
                          {NumberFormat.currencyFormat(10000)}
                        </label>
                      </div>
                    </div>
                    <div className="select-none my-3 flex flex-col space-y-3 w-full justify-center">
                      <div className="w-full">
                        <input
                          type={"number"}
                          className={`font-medium
                h-10 w-full p-3 rounded-md 
                ${error === "Minimum amount 100 . . !"
                              ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                              : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                            }`}
                          name="amount"
                          value={amount}
                          placeholder="Enter amount"
                          onChange={(e) => setAmount(e.target.value)}
                        />
                        {error === "Minimum amount 100 . . !" ? (
                          <p className="text-sm text-red-600 mt-1">{error}</p>
                        ) : null}
                      </div>
                      {/* <button
                        className="w-full text-center py-1 rounded-md
                        active:bg-blue-400 bg-blue-600 font-bold
                        text-gray-50 hover:bg-blue-400
                          focus:outline-none my-1"
                        id="open-btn"
                        onClick={() => handleCopyClick()}
                      >
                        <span ref={textRef} className="hidden">
                          {upiId}
                        </span>
                        <span>Tap to copy - UPI</span>
                      </button>

                      <p className="text-sm text-black font-medium">
                        After payment copy and past transaction id
                      </p>
                      <button
                        className="w-full text-center py-1 rounded-md
                        active:bg-red-400 bg-red-600 font-bold
                        text-gray-50 hover:bg-red-400
                          focus:outline-none my-1"
                        id="open-btn"
                        onClick={() => {
                          const email = "noreply.multigameserver@gmail.com";
                          const subject = "Add Amount Issue";
                          const body = `We are having a issue with adding amount!`;
                          const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
                            subject
                          )}&body=${encodeURIComponent(body)}`;
                          window.location = mailtoLink;
                        }}
                      >
                        {`Any issues -> sent an email`}
                      </button>

                      <div className="w-full">
                        <input
                          type={"text"}
                          className={`font-medium
                h-10 w-full p-3 rounded-md 
                ${
                  error === "Enter transaction Id . . !"
                    ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                    : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                }`}
                          name="transactionId"
                          value={transactionId}
                          placeholder="Enter transaction Id"
                          onChange={(e) => setTransactionId(e.target.value)}
                        />
                        {error === "Enter transaction Id . . !" ? (
                          <p className="text-sm text-red-600 mt-1">{error}</p>
                        ) : null}
                      </div> */}
                    </div>
                    {/* <div
                      className="flex ml-5 my-4 font-medium"
                      style={{
                        color:
                          error === "Agree to continue . . !" ? "red" : "black",
                      }}
                    >
                      <Checkbox
                        checked={checkBox ? true : false}
                        onChange={onChange}
                      >
                        Agree to add the amount
                      </Checkbox>
                    </div> */}
                    <div className="w-full h-px bg-slate-400 mt-1" />
                    <div
                      className="flex flex-row justify-between
             items-center my-3 mr-2"
                    >
                      <h1 className="text-left text-lg font-medium">
                        Amount :{" "}
                        {NumberFormat.currencyFormat(parseInt(amount))}
                      </h1>

                      <div className="flex justify-end space-x-5">
                        <button
                          className="w-16 text-center py-1 rounded-md
                  active:bg-gray-200 bg-gray-100 font-bold
                 text-gray-600 hover:bg-gray-200
                  focus:outline-none my-1"
                          id="open-btn"
                          onClick={() => onHandleCancel(setError)}
                        >
                          Cancel
                        </button>
                        <button
                          className="w-16 text-center py-1 rounded-md
                           active:bg-green-700 bg-green-500 font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-1"
                          id="open-btn"
                          onClick={() => onHandleAddAmount(setError, amount)}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default AddAmountModel;
