import { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const storeUserId = localStorage.getItem("userId");
    if (storedToken) {
      setAuthToken(storedToken);
      setUserId(storeUserId);
    }
  }, []);

  const updateAuthToken = (newToken, userId) => {
    // console.log(newToken, userId)
    localStorage.setItem("authToken", newToken);
    localStorage.setItem("userId", userId);
    setAuthToken(newToken);
    setUserId(userId);
  };

  const removeAuthToken = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    setAuthToken(null);
    setUserId(null);
  };

  return (
    <AuthContext.Provider
      value={{ authToken, userId, updateAuthToken, removeAuthToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
