import { API_URL } from "./config";

export async function getBettingTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll/${page}/${pageSize}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getBettingOneMinuteTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-one/${page}/${pageSize}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getBettingFiveMinutesTable(authToken, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/dice-roll-five/${page}/${pageSize}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}
