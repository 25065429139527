const validate = (values) => {
  const errors = {};

  /* validating email using regex to pass email */
  if (!values.email) {
    errors.email = (
      <div className="text-red-500 font-medium text-left pl-1">
        *An email address is required.
      </div>
    );
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = (
      <div className="text-red-500 font-medium text-left pl-1">
        Incorrect email address.
      </div>
    );
  } else if (/^[A-Z]/.test(values.email)) {
    errors.email = (
      <div className="text-red-500 font-medium text-left pl-1">
        *All characters must be in lowercase.
      </div>
    );
  } else if (!/@(gmail\.com|icloud\.com|hotmail\.com)$/i.test(values.email)) {
    errors.email = (
      <div className="text-red-500 font-medium text-left pl-1">
        *Only email addresses from @gmail.com, @icloud.com, or @hotmail.com are allowed.
      </div>
    );
  }

  /* validating passwords */
  if (!values.password) {
    errors.password = (
      <div className="text-red-500 font-medium text-left pl-1">
        *A password is required.
      </div>
    );
  } else if (values.password.length <= 7) {
    errors.password = (
      <div className="text-red-500 font-medium text-left pl-1">
        Minimum 8 characters.
      </div>
    );
  }

  /* validating phone number */
  if (!values.number) {
    errors.number = (
      <div className="text-red-500 font-medium text-left pl-1">
        *A phone number is required.
      </div>
    );
  } else if (!/^\d{10}$/i.test(values.number)) {
    errors.number = (
      <div className="text-red-500 font-medium text-left pl-1">
        *Phone number must be exactly 10 digits.
      </div>
    );
  }

  return errors;
};

export default validate;
