import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";

function DebitModel({
  isModalVisible,
  setIsModalVisible,
  handleSendEmail,
}) {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleCancel = () => {
    setIsModalVisible(false);
    setMessage("")
    setError("")
  };

  return (
    <div>
      <Transition.Root show={isModalVisible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleCancel()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex items-start mt-16 
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left px-2 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="mt-3 text-center">
                    <div className="flex items-center justify-between py-2 px-5">
                      <h1 className="text-left font-medium text-lg">
                        Contact Form
                      </h1>
                      <IoIosCloseCircle
                        onClick={handleCancel}
                        color="#000"
                        className="cursor-pointer text-2xl"
                      />
                    </div>
                    <div className="w-full h-px bg-slate-500 mt-1" />
                    <div className="mt-5">
                      <h1 className="text-center text-lg font-medium">
                        How can we help you ?
                      </h1>
                      <div
                        className="select-none mt-3 flex w-full justify-center"
                      >
                        <div className="mx-5 w-full">
                          <textarea
                            name="postContent"
                            placeholder="Enter the message"
                            rows={6}
                            cols={40}
                            className={`font-medium w-full p-3 rounded-md 
                            ${(error === "Enter Message" && message === "") ?
                                "bg-red-100 text-red-600 border-2 border-red-300  placeholder:text-red-600"
                                : "bg-gray-100 text-gray-600 "
                              }`}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                          {(error === "Enter Message" && message === "") ? (
                            <p className="text-sm text-red-600 mt-1">Please enter message!</p>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="flex ml-5 my-4 font-medium"
                        style={{

                        }}
                      >
                      </div>

                      <div className="w-full h-px bg-slate-400 mt-1" />
                      <div className="flex justify-end space-x-5 p-3">
                        <button
                          className="text-black bg-gray-100 
      rounded-md px-5 py-1 text-base font-medium hover:bg-gray-200"
                          id="open-btn"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-500 text-white 
                          rounded-md px-4 py-1 text-base font-medium hover:bg-blue-600"
                          id="open-btn"
                          onClick={() => {
                            if (message === "") {
                              setError("Enter Message");
                            } else {
                              handleSendEmail(message);
                              setError("");
                              setMessage("");
                            }
                          }}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default DebitModel;
