import React from "react";
import { BiSkipPrevious, BiSkipNext } from "react-icons/bi";
import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import moment from "moment";

function UserBettingTable({
  userBettingViewVisible,
  setUserBettingViewVisible,
  userBettingHistoryView,
  nextPage,
  previousPage,
  page,
  totalPages,
  setPage,
}) {
  return (
    <div>
      <Transition.Root show={userBettingViewVisible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setUserBettingViewVisible}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex  items-start mt-16
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative
                 transform rounded-lg overflow-x-auto
                  bg-white text-left shadow-xl transition-all
                   sm:my-8 w-screen sm:max-w-lg py-3"
                >
                  <div className="flex items-center justify-between m-5">
                    <h5
                      className="text-xl font-bold leading-none
                   text-gray-900"
                    >
                      Your Prediction's History
                    </h5>
                    <IoIosCloseCircleOutline
                      onClick={() => setUserBettingViewVisible(false)}
                      color="#000"
                      className="cursor-pointer text-2xl"
                    />
                  </div>
                  {userBettingHistoryView?.length > 0 ? (
                    <div>
                      {userBettingHistoryView?.map((item, index) => {
                        return (
                          <div
                            className={`py-3 px-2 my-2 border-2 
                            mx-5 rounded sm:py-4 ${item.final === "win"
                                ? "bg-green-50"
                                : item.final === "lose"
                                  ? "bg-red-50"
                                  : "bg-gray-50"
                              } first-letter:
                            ${item.amountType === "Bonus" ? "border-yellow-300" : "border-black"}`}
                            key={index}
                          >
                            <div className="flex items-center space-x-4">
                              <div className="flex-1 min-w-0">
                                <p
                                  className="text-sm font-medium text-gray-900 truncate"
                                >
                                  Period :{" "}
                                  {item?.bettingItemTableDataBaseId}
                                  {" - "}
                                  {moment().format("hh:mm a")}
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                  Guess :{" "}
                                  {item.eventHit === ""
                                    ? item.numberHit
                                    : item.numberHit === 0
                                      ? item.eventHit
                                      : ""}{" "}
                                  - Amount : {item.amount}
                                </p>
                              </div>
                              <div
                                className={`inline-flex items-center text-base
                       font-medium ${item.final === "win"
                                    ? "text-green-500"
                                    : item.final === "lose"
                                      ? "text-red-500"
                                      : "text-blue-600"
                                  }`}
                              >
                                {item.finalAmount
                                  ? item.finalAmount
                                  : "waiting..."}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex items-center select-none justify-end mr-5 mt-3">
                        <BiSkipPrevious
                          onClick={() =>
                            previousPage === null ? null : setPage(previousPage)
                          }
                          size={30}
                          color={previousPage === null ? "#ccc" : "#000"}
                          className={`${previousPage === null
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                            }`}
                        />
                        <h1>
                          {page} of {totalPages}
                        </h1>
                        <BiSkipNext
                          onClick={() =>
                            nextPage === null ? null : setPage(nextPage)
                          }
                          size={30}
                          color={nextPage === null ? "#ccc" : "#000"}
                          className={`${nextPage === null
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                            }`}
                        />
                      </div>
                    </div>
                  ) : (
                    <h1
                      className="flex  xs:text-xs justify-center
                      ps:text-sm md:text-lg font-sans font-bold
                      text-gray-800  "
                    >
                      No Data
                    </h1>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default UserBettingTable;
