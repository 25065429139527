import React from "react";
import DashboardHeader from "../components/header/DashboardHeader";
import ProfileScreen from "../screen/profile/ProfileScreen";

function AccountScreen({
  page,
  pageColor,
  setDashBoardTable,
  setDashBoardTableColor,
  setUserBettingHistory,
  userDetail,
  setUserDetail,
  tab,
  setTab,
  setScreen,
  topTab,
  setTopTab,
  loading,
  setLoading
}) {
  return (
    <div>
      <DashboardHeader
        page={page}
        pageColor={pageColor}
        setDashBoardTable={setDashBoardTable}
        setDashBoardTableColor={setDashBoardTableColor}
        setUserBettingHistory={setUserBettingHistory}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        tab={tab}
        setTab={setTab}
        setScreen={setScreen}
        topTab={topTab}
        setTopTab={setTopTab}
        loading={loading}
        setLoading={setLoading}
      />
      <ProfileScreen userDetail={userDetail} setUserDetail={setUserDetail} />
    </div>
  );
}

export default AccountScreen;
