import React from "react";

function BettingButtonsColor({ isDisabled, showModal }) {
  return (
    // <div className={`bg-gradient-to-r from-cyan-400 to-blue-400 rounded-b-xl`}> 
    <div className={`bg-gray-100 rounded-b-xl`}>
      {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 hidden md:flex">
        <div className="flex-1 space-y-2 ps:space-x-3 md:space-y-5 lg:space-y-8">
          Number Betting Button
          <div className="flex items-center mt-5 justify-evenly md:my-3">
            <div>
              <button
                style={{ backgroundColor: isDisabled ? "#888" : "#33cc33" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  }
          text-white rounded-md px-5 py-1 font-medium shadow-lg`}
                onClick={() => (isDisabled ? null : showModal("odd"))}
              >
                Join Green
              </button>
              <div className="flex justify-around">
                <BsFillDice1Fill
                  style={{ color: isDisabled ? "#888" : "#fff" }}
                  className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    } text-xs mt-1`} />
                <BsFillDice3Fill
                  style={{ color: isDisabled ? "#888" : "#fff" }}
                  className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    } text-xs mt-1`} />
                <BsFillDice5Fill
                  style={{ color: isDisabled ? "#888" : "#fff" }}
                  className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    } text-xs mt-1`} />
              </div>
            </div>
            <div>
              <button
                style={{ backgroundColor: isDisabled ? "#888" : "#33cc33" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  }
                  text-white rounded-md px-5 py-1 font-medium shadow-lg`}
                onClick={() => (isDisabled ? null : showModal("even"))}
              >
                Join Red
              </button>
              <div className="flex justify-around">
                <BsFillDice2Fill
                  style={{ color: isDisabled ? "#888" : "#fff" }}
                  className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    } text-xs mt-1`} />
                <BsFillDice4Fill
                  style={{ color: isDisabled ? "#888" : "#fff" }}
                  className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    } text-xs mt-1`} />
                <BsFillDice6Fill
                  style={{ color: isDisabled ? "#888" : "#fff" }}
                  className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                    } text-xs mt-1`} />
              </div>
            </div>
            <div
              style={{
                background: "#00000000",
                color: "#000",
                border: 0,
                borderRadius: 5,
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => (isDisabled ? null : showModal(1))}
            >
              <TbSquareRoundedNumber1Filled
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed " : "cursor-pointer "
                  } text-4xl rounded-full shadow-lg`}
              />
              <BsFillDice1Fill
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  } text-xs mt-1`} />
            </div>
            <div
              style={{
                background: "#00000000",
                color: "#000",
                border: 0,
                borderRadius: 5,
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => (isDisabled ? null : showModal(2))}
            >
              <TbSquareRoundedNumber2Filled
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed " : "cursor-pointer "
                  } text-4xl rounded-full shadow-lg`}
              />
              <BsFillDice2Fill
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  } text-xs mt-1`} />
            </div>
            <div
              style={{
                background: "#00000000",
                color: "#000",
                border: 0,
                borderRadius: 5,
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => (isDisabled ? null : showModal(3))}
            >
              <TbSquareRoundedNumber3Filled
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed " : "cursor-pointer "
                  } text-4xl rounded-full shadow-lg`}
              />
              <BsFillDice3Fill
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  } text-xs mt-1`} />
            </div>
            <div
              style={{
                background: "#00000000",
                color: "#000",
                border: 0,
                borderRadius: 5,
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => (isDisabled ? null : showModal(4))}
            >
              <TbSquareRoundedNumber4Filled
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed " : "cursor-pointer "
                  } text-4xl rounded-full shadow-lg`}
              />
              <BsFillDice4Fill
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  } text-xs mt-1`} />
            </div>
            <div
              style={{
                background: "#00000000",
                border: 0,
                borderRadius: 5,
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => (isDisabled ? null : showModal(5))}
            >
              <TbSquareRoundedNumber5Filled
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed " : "cursor-pointer "
                  } text-4xl rounded-full shadow-lg`}
              />
              <BsFillDice5Fill
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  } text-xs mt-1`} />
            </div>
            <div
              style={{
                background: "#00000000",
                color: "#000",
                border: 0,
                borderRadius: 5,
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => (isDisabled ? null : showModal(6))}
            >
              <TbSquareRoundedNumber6Filled
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed " : "cursor-pointer "
                  } text-4xl rounded-full shadow-lg`}
              />
              <BsFillDice6Fill
                style={{ color: isDisabled ? "#888" : "#fff" }}
                className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  } text-xs mt-1`} />
            </div>
          </div>
        </div>
      </div> */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex">
        <div className="flex-1">
          {/* Number Betting Button */}
          <div className="flex-1 flex mt-3 flex-row justify-between space-x-6 ">
            <button
              style={{ backgroundColor: isDisabled ? "#888" : "#16a34a" }}
              className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                }
           text-white rounded-md font-medium text-sm flex-1 py-2 shadow-lg`}
              onClick={() => (isDisabled ? null : showModal("green"))}
            >
              Join Green
            </button>
            <button
              style={{ backgroundColor: isDisabled ? "#888" : "#b91c1c" }}
              className={`${isDisabled ? "cursor-not-allowed" : "cursor-pointer"
                }
              text-white rounded-md font-medium text-sm flex-1 shadow-lg`}
              onClick={() => (isDisabled ? null : showModal("red"))}
            >
              Join Red
            </button>
          </div>
          <div className="space-y-3 my-5">
            <div
              className="grid grid-cols-5 grid-rows-1
      place-items-center place-content-center justify-items-center"
            >
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#b91c1c",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(0))}
              >
                <p className="text-center text-lg font-medium text-white">0</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#16a34a",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(1))}
              >
                <p className="text-center text-lg font-medium text-white">1</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#b91c1c",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(2))}
              >
                <p className="text-center text-lg font-medium text-white">2</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#16a34a",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(3))}
              >
                <p className="text-center text-lg font-medium text-white">3</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#b91c1c",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(4))}
              >
                <p className="text-center text-lg font-medium text-white">4</p>
              </div>
            </div>
            <div
              className="grid grid-cols-5 grid-rows-1
      place-items-center place-content-center justify-items-center"
            >
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#16a34a",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(5))}
              >
                <p className="text-center text-lg font-medium text-white">5</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#b91c1c",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(6))}
              >
                <p className="text-center text-lg font-medium text-white">6</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#16a34a",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(7))}
              >
                <p className="text-center text-lg font-medium text-white">7</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#b91c1c",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(8))}
              >
                <p className="text-center text-lg font-medium text-white">8</p>
              </div>
              <div
                style={{
                  background: "#00000000",
                  color: "#000",
                  border: 0,
                  borderRadius: 5,
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isDisabled ? "#888" : "#16a34a",
                  width: 60,
                  height: 40
                }}
                onClick={() => (isDisabled ? null : showModal(9))}
              >
                <p className="text-center text-lg font-medium text-white">9</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BettingButtonsColor;
