import React, { useState } from "react";
import validate from "./Validation/ValidateLogin";
import { useFormik } from "formik";
import { authLogin } from "./../lib/api/auth";
import { useAuth } from "../context/AuthContext";
import { getUserByEmail } from "../lib/api/user";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function LoginForm({ changeLayoutType }) {
  const { updateAuthToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [view, setView] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validate,
    onSubmit: (values) => {
      logIn(values.email, values.password);
    }
  });

  //Login
  const logIn = async (email, password) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const resAuth = await authLogin({ email: email, password: password });
      getUserByEmail(resAuth.authToken, email).then(async (res) => {
        await updateAuthToken(resAuth.authToken, res?.id);
      });
      setError(undefined);
      setLoading(false);
    } catch (e) {
      setError(e.message || JSON.stringify(e));
      setLoading(false);
    }
  };

  return (
    <div
      className="select-none px-9 flex 
    items-center justify-center"
    >
      {/* <form onSubmit={formik.handleSubmit}>
        <div className="w-full">
          <h1 className="text-3xl font-bold txtGreen text-center">Welcome</h1>
          <div className="w-full flex justify-center">
            <div
              id="loading-bar"
              className="bgGreen rounded-full mt-1
                      h-1 w-16 mb-8"
            />
          </div>
          <div className="flex justify-center">
            <div className="space-y-3" style={{ width: "95%" }}>
              <div>
                <label
                  id="email"
                  className="font-bold
               text-black ml-1"
                >
                  Email
                </label>
                <input
                  type={"email"}
                  className={`font-medium
                 w-full h-10 p-3 rounded-md block  
                ${
                  formik.touched.email && formik.errors.email
                    ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                    : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                }`}
                  name="email"
                  placeholder="Enter email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1">{formik.errors.email}</p>
                ) : null}
              </div>

              {error !== undefined && (
                <h1
                  className="cursor-pointer w-full 
                  text-center text-sm text-red-600"
                >
                  {error}
                </h1>
              )}

              {!viewOTP && (
                <div>
                  <button
                    type="submit"
                    className="w-full text-center py-2 rounded-md bg-black  
                           active:bg-gray-700 bgGreen font-bold
                          text-white hover:bg-gray-700
                           focus:outline-none my-1"
                  >
                    {loading ? "Loading..." : "Sign In"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      {viewOTP && (
        <div>
          <OtpInput otpCode={otpCode} setOtpCode={setOtpCode} />
          {errorOTP !== undefined && (
            <h1
              className="cursor-pointer w-full 
                  text-center text-sm text-red-600"
            >
              {errorOTP}
            </h1>
          )}
          <div>
            <button
              type="submit"
              onClick={() => confirmOTP(formik.values.email, otpCode)}
              className="w-full text-center py-2 rounded-md bg-black  
                           active:bg-gray-700 bgGreen font-bold
                          text-white hover:bg-gray-700
                           focus:outline-none my-1"
            >
              {loadingOTP ? "Loading..." : "Verify"}
            </button>
          </div>
        </div>
      )} */}

      <div
        className="w-full bg-white rounded-lg mt-5
       sm:max-w-md xl:p-0"
      >
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            className="text-xl font-bold my-4 text-center
           leading-tight tracking-tight text-gray-700
            md:text-2xl"
          >
            Login to Continue!
          </h1>
          <form
            className="space-y-4 md:space-y-6"
            action="#"
            onSubmit={formik.handleSubmit}
          >
            <div>
              <label
                for="email"
                className="block mb-2 font-medium
                 text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={` border
                   text-gray-900
                  sm:text-sm rounded-lg 
                    block w-full p-2.5
                    ${
                      formik.touched.email && formik.errors.email
                        ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                        : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                    }  `}
                placeholder="abc@gmail.com"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1">{formik.errors.email}</p>
              ) : null}
            </div>
            <div>
              <label
                for="password"
                className="block mb-2 font-medium
                 text-gray-900"
              >
                Password
              </label>
              <div
                className={`border
                   text-gray-900
                  sm:text-sm rounded-lg 
                  flex flex-row justify-between items-center pr-2
                    ${
                      formik.touched.password && formik.errors.password
                        ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                        : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                    }`}
              >
                <input
                  type={view ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder={view ? "password" : "********"}
                  {...formik.getFieldProps("password")}
                  className={`p-2.5 flex-grow
                 sm:text-sm rounded-lg  
                   bg-gray-100 text-gray-600 focus:outline-none
                   ${
                     formik.touched.password && formik.errors.password
                       ? "bg-red-100 text-red-600 placeholder:text-red-600"
                       : "bg-gray-100 text-gray-600 "
                   }`}
                />
                <div className="cursor-pointer" onClick={() => setView(!view)}>
                  {view ? (
                    <AiFillEyeInvisible size={20} color="#000" />
                  ) : (
                    <AiFillEye size={20} color="#000" />
                  )}
                </div>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <p className="text-xs mt-1">{formik.errors.password}</p>
              ) : null}
            </div>
            <div className="flex justify-center items-center space-x-2">
              {error !== undefined && (
                <h1
                  className="cursor-pointer 
                  text-center text-sm text-red-600"
                >
                  {error}
                </h1>
              )}
              {error !== undefined && error === "User is not confirmed!" && (
                <p
                  className="text-black font-medium"
                  onClick={() => changeLayoutType("ConfirmUser")}
                >
                  Click here to confirm
                </p>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="w-full text-center py-2 rounded-md bg-green-600
                           active:bg-green-700 bgGreen font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-1"
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
          </form>

          <div className="flex flex-row justify-center items-center space-x-2">
            <p className="text-center text-gray-600 my-1">
              Don't have an account ?
            </p>
            <p
              className="font-medium text-center text-black my-1 cursor-pointer"
              onClick={() => changeLayoutType("SignUP")}
            >
              Create account.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
