import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Transition, Dialog } from "@headlessui/react";
import { Checkbox } from "antd";
import { Fragment } from "react";

function AccountAddModel({
  isModalVisibleAccount,
  setIsModalVisibleAccount,
  userId,
  onSubmitAccount,
  onDeleteAccount,
}) {
  const [checkBox, setCheckBox] = useState(false);
  const [accountType, setAccountType] = useState("Account");

  const [account, setAccount] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [upi, setUpi] = useState("");
  const [error, setError] = useState("");

  function onChange(e) {
    setCheckBox(e.target.checked);
  }

  function isValid_IFSC(IFSC) {
    // Regex to check valid IFSC
    let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);

    // If IFSC is empty return false
    if (IFSC == null) {
      return false;
    }
    // Return true if the IFSC matches the regex
    if (regex.test(IFSC) == true) {
      return true;
    }
    else {
      return false;
    }
  }

  function isValid_ACC(accountNumber) {
    // Define the regular expression pattern
    let regex = /^[0-9]{9,18}$/; // OR /^\d{9,18}$/;

    // Test if the account number matches the regex pattern
    return regex.test(accountNumber);
  }

  function isValid_UPI_ID(upi_Id) {
    // Regex to check valid upi_Id
    let regex = new RegExp(/^[a-zA-Z0-9.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/);

    // If upi_Id is empty return false
    if (upi_Id == null) {
      return false;
    }
    // Return true if the upi_Id matches the regex
    if (regex.test(upi_Id) == true) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleAccountAddOk = () => {
    if (accountType === "Account") {
      if (account === "") {
        setError("Enter account number . . !");
      } else if (!isValid_ACC(Number(account))) {
        setError("Enter valid account . . !");
      } else if (ifsc === "") {
        setError("Enter IFSC code . . !");
      } else if (!isValid_IFSC(ifsc)) {
        setError("Enter valid IFSC Code . . !");
      } else if (!checkBox) {
        setError("Agree to continue . . !");
      } else {
        let data = {
          accountNumber: account.toString(),
          ifscCode: ifsc,
          upiAddress: upi,
          userId: userId,
        };
        onSubmitAccount(data);
        setError("");
        setCheckBox(false);
        setUpi("");
        setAccount("");
        setIfsc("");
        setIsModalVisibleAccount(false);
      }
    } else {
      if (upi === "") {
        setError("Enter upi id . . !");
      } else if (!isValid_UPI_ID(upi)) {
        setError("Enter valid UPI . . !");
      } else if (!checkBox) {
        setError("Agree to continue . . !");
      } else {
        let data = {
          accountNumber: account.toString(),
          ifscCode: ifsc,
          upiAddress: upi,
          userId: userId,
        };
        onSubmitAccount(data);
        setError("");
        setCheckBox(false);
        setUpi("");
        setAccount("");
        setIfsc("");
        setIsModalVisibleAccount(false);
      }
    }
  };

  const handleCancel = () => {
    setAccount("");
    setIfsc("");
    setUpi("");
    setIsModalVisibleAccount(false);
    setCheckBox(false);
    setAccountType("Account");
    setError("");
  };

  return (
    <div>
      <Transition.Root show={isModalVisibleAccount} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleCancel()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex items-start mt-16 
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left px-2 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="mt-3 text-center">
                    <div className="flex items-center justify-between py-2">
                      <h1 className="text-left font-medium text-lg">
                        Account Details
                      </h1>
                      <IoIosCloseCircle
                        onClick={handleCancel}
                        color="#000"
                        className="cursor-pointer text-2xl"
                      />
                    </div>
                    <div className="w-full h-px bg-slate-500 mt-1" />
                    <div className="mt-1">
                      {/* <div className="flex justify-around my-3">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setAccountType("Account");
                            setError("");
                            setAccount("");
                            setIfsc("");
                            setUpi("");
                            setCheckBox(false);
                          }}
                        >
                          <h1
                            className={`${accountType === "Account"
                              ? "underline text-black font-medium underline-offset-2"
                              : "text-gray-500"
                              }`}
                          >
                            Account
                          </h1>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setAccountType("UPI");
                            setError("");
                            setAccount("");
                            setIfsc("");
                            setUpi("");
                            setCheckBox(false);
                          }}
                        >
                          <h1
                            className={`${accountType === "UPI"
                              ? "underline text-black font-medium underline-offset-2"
                              : "text-gray-500"
                              }`}
                          >
                            UPI
                          </h1>
                        </div>
                      </div> */}

                      {accountType === "Account" ? (
                        <div>
                          <div>
                            <h1 className="font-medium text-black ml-5 text-left">
                              Account No
                            </h1>
                            <div className="mt-1 select-none flex justify-center">
                              <div className="mx-5 w-full">
                                <input
                                  type={"number"}
                                  className={`h-10 w-full p-3 rounded-md 
                                  ${account === "" && error === "Enter account number . . !"
                                      ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                                      : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                                    }`}
                                  name="account"
                                  placeholder="Enter Account No"
                                  value={account}
                                  onChange={(e) => setAccount(e.target.value)}
                                />
                                {account === "" && error === "Enter account number . . !" ? (
                                  <p className="text-sm text-red-600 mt-1">
                                    {error}
                                  </p>
                                ) : null}
                                {account !== "" && !isValid_ACC(Number(account)) ? (
                                  <p className="text-sm text-yellow-600 mt-1">
                                    Enter valid account . . !
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div>
                            <h1 className="font-medium text-black ml-5 text-left">
                              IFSC Code
                            </h1>
                            <div className="mt-1 select-none flex justify-center">
                              <div className="mx-5 w-full">
                                <input
                                  type={"text"}
                                  className={`h-10 w-full p-3 rounded-md 
                                  ${ifsc === "" && error === "Enter IFSC code . . !"
                                      ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                                      : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                                    }`}
                                  name="ifsc"
                                  placeholder="Enter IFSC Code"
                                  value={ifsc}
                                  onChange={(e) => setIfsc(e.target.value)}
                                />
                                {ifsc === "" && error === "Enter IFSC code . . !" ? (
                                  <p className="text-sm text-red-600 mt-1">
                                    {error}
                                  </p>
                                ) : null}
                                {ifsc !== "" && !isValid_IFSC(ifsc) ? (
                                  <p className="text-sm text-yellow-600 mt-1">
                                    Enter valid IFSC Code . . !
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h1 className="font-medium text-black ml-5 text-left">
                            UPI ID
                          </h1>
                          <div className="mt-1 select-none flex justify-center">
                            <div className="mx-5 w-full">
                              <input
                                type={"text"}
                                className={`h-10 w-full p-3 rounded-md 
                                ${upi === "" && error === "Enter upi id . . !"
                                    ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                                    : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                                  }`}
                                name="upi"
                                value={upi}
                                placeholder="Enter UPI Id"
                                onChange={(e) => setUpi(e.target.value)}
                              />
                              {upi === "" && error === "Enter upi id . . !" ? (
                                <p className="text-sm text-red-600 mt-1">
                                  {error}
                                </p>
                              ) : null}
                              {upi !== "" && !isValid_UPI_ID(upi) ? (
                                <p className="text-sm text-yellow-600 mt-1">
                                  Enter valid UPI . .!
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="my-2 text-center text-xs text-green-600 font-medium"
                      >
                        Provide correct details
                      </div>
                      <div
                        className="flex ml-5 mt-2 mb-4 font-medium"
                        style={{
                          color:
                            error === "Agree to continue . . !"
                              ? "red"
                              : "black",
                        }}
                      >
                        <Checkbox
                          checked={checkBox ? true : false}
                          onChange={onChange}
                        >
                          Agree to add account
                        </Checkbox>
                      </div>

                      <div className="w-full h-px bg-slate-400 mt-1" />
                      <div className="flex justify-end space-x-5 p-3">
                        <button
                          className="text-black bg-gray-100 
                            rounded-md px-5 py-2 text-base font-medium hover:bg-gray-200"
                          id="open-btn"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-500 text-white rounded-md px-4 py-2 text-base font-medium
                        hover:bg-blue-600"
                          id="open-btn"
                          onClick={() => handleAccountAddOk()}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default AccountAddModel;
