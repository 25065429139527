import { API_URL } from "./config";

export async function getUserBettingTable(authToken, userId, page, pageSize) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/user-betting/${userId}/${page}/${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getUserCurrentBettingTable(
  authToken,
  userId,
  type,
  table
) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/user-betting/current/betting/${userId}/${type}/${table}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export const postUserBetting = async (authToken, data) => {
  const res = await fetch(`${API_URL}/user-betting`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json"
    },
    body: JSON.stringify(data)
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error during the hitting process");
  }
  return await res.json();
};
