import React from "react";
import DashboardHeader from "../components/header/DashboardHeader";
import DashboardScreen from "../screen/dashboard/DashboardScreen";

function HomeScreen({
  page,
  pageColor,
  setDashBoardTable,
  setDashBoardTableColor,
  setUserBettingHistory,
  userDetail,
  setUserDetail,
  dashBoardTable,
  dashBoardTableColor,
  userBettingHistory,
  setPage,
  setPageColor,
  tab,
  setTab,
  setScreen,
  topTab,
  setTopTab,
  loading,
  setLoading
}) {
  return (
    <div>
      <DashboardHeader
        page={page}
        pageColor={pageColor}
        setDashBoardTable={setDashBoardTable}
        setDashBoardTableColor={setDashBoardTableColor}
        setUserBettingHistory={setUserBettingHistory}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        tab={tab}
        setTab={setTab}
        setScreen={setScreen}
        topTab={topTab}
        setTopTab={setTopTab}
        loading={loading}
        setLoading={setLoading}
      />
      {/* {loading ? (
        <>Loading...</>
      ) : ( */}
      <DashboardScreen
        tab={tab}
        dashBoardTable={dashBoardTable}
        dashBoardTableColor={dashBoardTableColor}
        userBettingHistory={userBettingHistory}
        setPage={setPage}
        setPageColor={setPageColor}
      />
      {/* )} */}
    </div>
  );
}

export default HomeScreen;
