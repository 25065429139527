import { Checkbox } from "antd";
import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Transition, Dialog } from "@headlessui/react";
import NumberFormat from "../../utilities/NumberFormat";
import { Fragment } from "react";

function DebitModel({
  walletAmount,
  isModalVisible,
  setIsModalVisible,
  accountArray,
  handleDebitOk,
  viewDetails
}) {
  const [checkBox, setCheckBox] = useState(false);
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState("");
  const [account, setAccount] = useState(undefined);

  function onChange(e) {
    setCheckBox(e.target.checked);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setCheckBox(false);
    setAmount(0);
    setError("");
    setAccount(undefined);
  };

  const AccountCard = ({ item, viewDetails }) => {
    return (
      <div
        className={`flex flex-1 flex-row my-3 select-none py-3  cursor-pointer
       rounded-lg border-2 xs:text-xs sm:text-base md:text-sm lg:text-base
       ${account !== undefined && item?.id === account?.id
            ? "bg-green-50 border-emerald-100 shadow-emerald-100  shadow"
            : "bg-white border-sky-100 "
          }`}
        onClick={() => setAccount(item)}
      >
        <div className="flex flex-grow items-center pl-3">
          <div>
            {item?.accountNumber === "" ? (
              <div className="lg:flex">
                <h1 className="flex text-sm font-medium text-gray-800">UPI</h1>
                <h1 className="flex text-sm text-gray-600 ml-1">
                  {item?.upiAddress}
                </h1>
              </div>
            ) : (
              <div className="py-1">
                <div className="lg:flex">
                  <h1 className="flex text-sm font-medium text-gray-800">
                    AccountNumber
                  </h1>
                  <h1 className="flex text-sm text-gray-600 ml-1">
                    {
                      viewDetails ?
                        item?.accountNumber :
                        item?.accountNumber?.slice(0, 2) + "******" + item?.accountNumber?.slice(12, 14)
                    }
                  </h1>
                </div>
                <div className="lg:flex pt-1">
                  <h1 className="flex text-sm font-medium text-gray-800">
                    IFSC
                  </h1>
                  <h1 className="flex text-sm text-gray-600 ml-1">
                    {
                      viewDetails ?
                        item?.ifscCode :
                        item?.ifscCode?.slice(0, 2) + "******" + item?.ifscCode?.slice(9, 11)
                    }
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Transition.Root show={isModalVisible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleCancel()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex items-start mt-16 
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left px-2 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="mt-3 text-center">
                    <div className="flex items-center justify-between py-2 px-5">
                      <h1 className="text-left font-medium text-lg">
                        Change to account
                      </h1>
                      <IoIosCloseCircle
                        onClick={handleCancel}
                        color="#000"
                        className="cursor-pointer text-2xl"
                      />
                    </div>
                    <div className="w-full h-px bg-slate-500 mt-1" />
                    <div className="mt-5">
                      <h1 className="text-left ml-5 font-medium">
                        Wallet :{" "}
                        <span className="font-bold text-green-500 tracking-wide">
                          {walletAmount !== undefined &&
                            NumberFormat.currencyFormat(walletAmount)}
                        </span>
                      </h1>
                      <div
                        className="
     select-none mt-3 flex w-full justify-center"
                      >
                        <div className="mx-5 w-full">
                          <input
                            type={"number"}
                            className={`font-medium
        h-10 w-full p-3 rounded-md 
        ${error === "Enter the amount first . . !" ||
                                error === `Enter the less than ${walletAmount} rupees`
                                ? "bg-red-100 text-red-600 border-2 border-red-300  placeholder:text-red-600"
                                : "bg-gray-100 text-gray-600 "
                              }`}
                            name="email"
                            placeholder="Enter amount"
                            onChange={(e) => setAmount(e.target.value)}
                          />
                          {error === "Enter the amount first . . !" ||
                            error ===
                            `Enter the less than ${walletAmount} rupees` ? (
                            <p className="text-sm text-red-600 mt-1">{error}</p>
                          ) : null}
                          {error === "Minimum ₹1000" ? (
                            <p className="text-sm text-red-600 mt-1">{error}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="m-3">
                        <h1
                          className="text-left font-medium"
                          style={{
                            color:
                              error === "Select the account" ? "red" : "black",
                          }}
                        >
                          Account List :{" "}
                          {error === "Select the account" ? (
                            <p className="text-xs text-red-600 mt-1">{error}</p>
                          ) : null}
                        </h1>

                        {accountArray?.length === 0 ? (
                          <div>
                            <h1 className="text-red-500">
                              Account list is empty. Go back and add the account
                              first.
                            </h1>
                          </div>
                        ) : (
                          <div>
                            {accountArray?.map((item) => (
                              <AccountCard key={item.id} item={item} viewDetails={viewDetails} />
                            ))}
                          </div>
                        )}
                      </div>

                      <div
                        className="flex ml-5 my-4 font-medium"
                        style={{
                          color:
                            error === "Agree to continue . . !"
                              ? "red"
                              : "black",
                        }}
                      >
                        <Checkbox
                          checked={checkBox ? true : false}
                          onChange={onChange}
                        >
                          Agree to change the amount
                        </Checkbox>
                      </div>

                      <div className="w-full h-px bg-slate-400 mt-1" />
                      <div className="flex justify-end space-x-5 p-3">
                        <button
                          className="text-black bg-gray-100 
      rounded-md px-5 py-1 text-base font-medium hover:bg-gray-200"
                          id="open-btn"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-500 text-white rounded-md px-4 py-1 text-base font-medium
         hover:bg-blue-600"
                          id="open-btn"
                          onClick={() =>
                            handleDebitOk(
                              amount,
                              checkBox,
                              setCheckBox,
                              setError,
                              account,
                              setAccount,
                              setAmount
                            )
                          }
                        >
                          Hit
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default DebitModel;
