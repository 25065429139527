import React, { useState } from "react";
import validate from "../Validation/ValidateConfirm";
import { useFormik } from "formik";
import OtpInput from "../VerifyOTP/OTPInput";
import { getConfirmCode, confirmSignUP } from "../../lib/api/auth";

function ConfirmForm({ changeLayoutType }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [viewOTP, setViewOTP] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [errorOTP, setErrorOTP] = useState(undefined);
  const [otpCode, setOtpCode] = useState("");
  const isOtpFilled = otpCode.length === 6;

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      getEmailConfirmCode(values.email);
    },
  });

  //Login
  const getEmailConfirmCode = async (email) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await getConfirmCode({ email: email });
      setLoading(false);
      setError(undefined);
      setViewOTP(true);
    } catch (e) {
      setError(e.message || JSON.stringify(e));
      setLoading(false);
    }
  };

  //ConfirmOTP
  const confirmOTP = async (email, otp) => {
    if (!isOtpFilled) {
      alert("fill 6 otp");
    } else {
      if (loadingOTP) {
        return;
      }
      setLoadingOTP(true);

      if (typeof email !== "string") {
        return;
      }
      try {
        await confirmSignUP({ email, emailToken: otp });
        setErrorOTP(undefined);
        setLoadingOTP(false);
        changeLayoutType("Login");
      } catch (e) {
        console.log(e);
        setErrorOTP(e.message || JSON.stringify(e));
        setLoadingOTP(false);
      }
    }
  };

  return (
    <div
      className="select-none px-9 flex 
    items-center justify-center"
    >
      <div
        className="w-full bg-white rounded-lg shadow-md mt-5
       sm:max-w-md xl:p-0"
      >
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            className="text-xl font-bold my-4 text-center
           leading-tight tracking-tight text-gray-900
            md:text-2xl"
          >
            Confirm Account
          </h1>
          <form
            className="space-y-4 md:space-y-6"
            action="#"
            onSubmit={formik.handleSubmit}
          >
            <div>
              <label
                for="email"
                className="block mb-2 font-medium
                 text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={` border
                   text-gray-900
                  sm:text-sm rounded-lg 
                    block w-full p-2.5
                    ${
                      formik.touched.email && formik.errors.email
                        ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                        : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                    }  `}
                placeholder="abc@gmail.com"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1">{formik.errors.email}</p>
              ) : null}
            </div>

            {error !== undefined && (
              <h1
                className="cursor-pointer w-full 
                  text-center text-sm text-red-600"
              >
                {error}
              </h1>
            )}

            {!viewOTP && (
              <button
                type="submit"
                className="w-full text-center py-2 rounded-md bg-green-600  
                           active:bg-green-700 bgGreen font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-1"
              >
                {loading ? "Loading..." : "Get Code"}
              </button>
            )}
          </form>
          {viewOTP && (
            <div>
              <OtpInput otpCode={otpCode} setOtpCode={setOtpCode} />
              {errorOTP !== undefined && (
                <h1
                  className="cursor-pointer w-full 
                  text-center text-sm text-red-600"
                >
                  {errorOTP}
                </h1>
              )}
              <div>
                <button
                  type="submit"
                  onClick={() => confirmOTP(formik.values.email, otpCode)}
                  className="w-full text-center py-2 rounded-md bg-green-600
                           active:bg-green-700 bgGreen font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-3"
                >
                  {loadingOTP ? "Loading..." : "Verify"}
                </button>
              </div>
            </div>
          )}

          <div className="flex flex-row justify-center items-center space-x-2">
            <p className="text-center text-gray-600 my-1">Back to Login ?</p>
            <p
              className="font-medium text-center text-black my-1"
              onClick={() => changeLayoutType("Login")}
            >
              Click here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmForm;
