import moment from "moment";
import { useState, useEffect } from "react";
import { RiCopperCoinFill } from "react-icons/ri";
import NumberFormat from "../../utilities/NumberFormat";

function Timer({
  serverLoading,
  setIsDisabled,
  serverTimer,
  setServerLoading,
  userDetail,
}) {
  const timer = moment.unix(serverTimer);
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    if (timer) {
      const intervalId = setInterval(() => {
        const currentTime = moment();
        const timeDifference = timer.diff(currentTime);

        if (timeDifference <= 0) {
          clearInterval(intervalId);
          setRemainingTime(moment.duration(0));

          // Check if the timer has already reached zero
          if (!serverLoading) {
            setServerLoading(true);
            // console.log("Timer reached zero");
          }
        } else {
          setRemainingTime(moment.duration(timeDifference));
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer, serverLoading, setServerLoading]);

  if (remainingTime !== null && remainingTime._milliseconds === 0) {
    return <div className="text-black">Loading...</div>;
  }

  const minutes = String(remainingTime?.minutes()).padStart(2, "0");
  const seconds = String(remainingTime?.seconds()).padStart(2, "0");

  if (remainingTime?.minutes() === 0 && remainingTime?.seconds() <= 30) {
    setIsDisabled(true);
  }

  const formattedTime = `${minutes}${seconds}`;

  const [digit1, digit2, digit3, digit4] = formattedTime.split("");

  return (
    <div>
      {remainingTime === null ? (
        <div className="text-black">Loading...</div>
      ) : (
        <div className="flex flex-col items-end justify-center">
          <p className="font-medium text-sm text-black">Count Down</p>
          <div
            className="text-xl flex space-x-1 text-black"
          >
            <span
              className={`text-black text-center font-bold
                  rounded`}
            >
              {digit1}
            </span>
            <span
              className={`text-black text-center font-bold
                  rounded`}
            >
              {digit2}
            </span>
            <span
              className={`text-black text-center font-bold
                  rounded`}
            >
              :
            </span>
            <span
              className={`text-black text-center font-bold
                  rounded`}
            >
              {digit3}
            </span>
            <span
              className={`text-black text-center font-bold
                  rounded`}
            >
              {digit4}
            </span>
          </div>
        </div>
      )}
      {/* {
        userDetail?.totalCredit === 0 || (userDetail?.wallet === 0 || userDetail?.wallet < 10)
          ? <a href={"/profile"}>
            <div
              className={`flex ${userDetail?.bonus <= 15
                ? "text-red-700 font-bold animate-bounce"
                : "text-black font-medium"
                } md:text-lg items-center mt-2`}
            >
              <p className="mr-1 text-sm text-black">Bonus Coins : </p>
              <RiCopperCoinFill className="text-black" />
              <p className="mr-1">
                {userDetail
                  ? NumberFormat.bonusFormat(userDetail?.bonus)
                  : "Loading..."}
              </p>
            </div>
          </a>
          :
          <a href={"/profile"}>
            <div
              className={`flex ${userDetail?.wallet <= 15
                ? "text-red-700 font-bold animate-bounce"
                : "text-gray-700 font-medium"
                } 
          md:text-lg items-center
       mt-2`}
            >
              <p className="mr-1 text-sm">Wallet : </p>
              <p className="mr-1">
                {userDetail
                  ? NumberFormat.currencyFormat(userDetail?.wallet)
                  : "Loading..."}
              </p>
            </div>
          </a>} */}
    </div>
  );
}
export default Timer;
