import { API_URL } from "./config";

export async function getPromotionUserList(authToken, usernames) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/user/by/username`, {
    method: 'POST', // Use POST method
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ usernames: usernames }),
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getUser(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getUserByEmail(authToken, email) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/user/by/email/${email}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}


export async function upDateUser(authToken, id, data) {
  if (!authToken) {
    return;
  }
  
  const res = await fetch(`${API_URL}/user/${id}`, {
    method: 'PUT', // Use POST method
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data }),
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}
