import React from 'react'
import WalletScreenComponent from '../screen/wallet/WalletScreen'
import { IoWallet } from "react-icons/io5";
import NumberFormat from '../utilities/NumberFormat';
import { RiCopperCoinFill } from "react-icons/ri";

function WalletScreen({
  userDetail,
  setUserDetail
}) {
  return (
    <div>
      <div className="bg-gradient-to-r from-cyan-400 to-blue-400
        items-center flex justify-center space-x-5">
        <div className="items-center flex flex-col justify-center text-white ">
          <p className='text-xl font-medium mt-2'>Wallet</p>
          <IoWallet size={30} className='mt-3' />
          <p className='text-3xl font-medium mt-1'>{NumberFormat.currencyFormat(userDetail?.wallet)}</p>
          <p className='text-sm mt-1 mb-8'>Total balance</p>
        </div>
        <div className="items-center flex h-20 w-px bg-white " />
        <div className="items-center flex flex-col justify-center text-amber-200">
          <p className='text-xl font-medium mt-2'>Promotion</p>
          <IoWallet size={30} className='mt-3' />

          <div className='flex text-3xl mt-1 items-center'>
            <RiCopperCoinFill className='' />
            <p className='font-medium'>
              {NumberFormat.bonusFormat(userDetail?.bonus)}
            </p>
          </div>
          <p className='text-sm mt-1 mb-8'>Total bonus</p>
        </div>
      </div>

      <WalletScreenComponent
        userDetail={userDetail}
        setUserDetail={setUserDetail}
      />
    </div>
  )
}

export default WalletScreen