import React from "react";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import "./table.css";
import { useState } from "react";
import { useEffect } from "react";

function DashboardTableColor({
  dashBoardTableArr,
  nextPage,
  previousPage,
  page,
  totalPages,
  setPage,
}) {
  const [info, setInfo] = useState(false);

  useEffect(() => {
    if (info) {
      // Set the info to false after 10 seconds
      const timer = setTimeout(() => {
        setInfo(false);
      }, 10000); // 10 seconds in milliseconds

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(timer);
    }
  }, [info]);

  return (
    <>
      {dashBoardTableArr?.length > 0 && (
        <div className="relative  select-none overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead
              className="text-xs text-gray-700 uppercase
        border-b border-gray-200"
            >
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 text-center">
                  Periods
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Number
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 text-center">
                  Event
                </th>
              </tr>
            </thead>
            {dashBoardTableArr?.slice(1).map((item, index) => {
              return (
                <tbody key={index}>
                  <tr className="border-b border-gray-200">
                    <th
                      scope="row"
                      className="px-5 py-3 text-center font-medium text-gray-900 whitespace-nowrap bg-gray-50"
                    >
                      {item?.bettingItemID}
                    </th>
                    <td className="px-5 py-3 text-center">
                      {" "}
                      {item?.resultNum}
                    </td>
                    <td className="px-5 py-3 items-center flex justify-center bg-gray-50">
                      {/* {item?.resultEvent} */}
                      <div className={`w-4 h-4 rounded-full
                       ${item?.resultEvent === "green" ? "bg-green-600" : "bg-red-700"}
                      `}></div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
          <div className="flex items-center justify-end">
            {/* <IoMdInformationCircle
              size={20}
              className="ml-5 text-black cursor-pointer"
              onClick={() => setInfo(true)}
            />
            {info && (
              <div className="moving-text-container">
                <span
                  className="moving-text text-sm font-medium
               text-gray-500"
                >
                  Every day data will regenerate
                </span>
              </div>
            )} */}
            <div className="flex items-center justify-end mr-5 mt-3">
              <BiSkipPrevious
                onClick={() =>
                  previousPage === null ? null : setPage(previousPage)
                }
                size={30}
                color={previousPage === null ? "#ccc" : "#000"}
                className={`${previousPage === null
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
              />
              {/* <h1>
                {page} of {totalPages}
              </h1> */}
              <h1>{page}</h1>
              <BiSkipNext
                onClick={() => (nextPage === null ? null : setPage(nextPage))}
                size={30}
                color={nextPage === null ? "#ccc" : "#000"}
                className={`${nextPage === null ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardTableColor;
