class NumberFormat {
  currencyFormat(num) {
    return "₹" + num?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  bonusFormat(num) {
    return num?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  getEtcFormat = (val) => {
    const token = val?.slice(0, 5) + "..." + val?.slice(-5);
    let tokenWithoutComma = token?.split(",")?.join("");
    return tokenWithoutComma;
  };
}
export default new NumberFormat();
