import React from "react";
import { BiSkipPrevious, BiSkipNext } from "react-icons/bi";
import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import moment from "moment";

function CreditTable({
  creditTableVisible,
  setCreditTableVisible,
  creditTableArray,
  nextPage,
  previousPage,
  page,
  totalPages,
  setPage,
}) {
  return (
    <div>
      <Transition.Root show={creditTableVisible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setCreditTableVisible}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex  items-start mt-16
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative
                 transform overflow-x-auto rounded-lg 
                  bg-white text-left shadow-xl transition-all
                   sm:my-8 w-screen sm:max-w-lg"
                >
                  <div className="flex items-center justify-between m-5">
                    <h5
                      className="text-xl font-bold leading-none
                   text-gray-900"
                    >
                      Deposit History
                    </h5>
                    <IoIosCloseCircleOutline
                      onClick={() => setCreditTableVisible(false)}
                      color="#000"
                      className="cursor-pointer text-2xl"
                    />
                  </div>
                  {creditTableArray?.length > 0 ? (
                    <>
                      {creditTableArray?.map((item, index) => {
                        return (
                          <div key={index}>
                            <div
                              className={`py-3 px-2 my-2
                            mx-5 rounded sm:py-4 ${item?.transactionIdVerification === "success"
                                  ? "bg-green-50"
                                  : item?.transactionIdVerification === "failed"
                                    ? "bg-red-50"
                                    : "bg-blue-50"
                                }`}
                              key={index}
                            >
                              <div className="flex items-center justify-between space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm text-gray-600 font-medium truncate">
                                    Amount : ₹{item.amount}
                                  </p>
                                  <p className="text-sm text-gray-600 font-medium truncate mt-2">
                                    Transaction Id : {item.transactionId}
                                  </p>
                                </div>
                                <div className="flex flex-col items-end">
                                  <p className="text-xs font-medium text-gray-900">
                                    {moment(new Date(item?.date)).format(
                                      "DD/MM/YY hh:mm a"
                                    )}
                                  </p>
                                  <div
                                    className={`inline-flex items-center text-base mt-2
                                      font-medium ${item?.transactionIdVerification ===
                                        "success"
                                        ? "text-green-500 font-medium"
                                        : item?.transactionIdVerification ===
                                          "failed"
                                          ? "text-red-500 font-medium"
                                          : "text-blue-500 font-medium"
                                      }`}
                                  >
                                    {item.transactionIdVerification}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div>
                        {/* <div className="px-5 mt-5">
                          <div className="flex items-center justify-between">
                            <h1 className="text-left font-medium text-lg">
                              Credit Table
                            </h1>
                            <IoIosCloseCircleOutline
                              onClick={() => setCreditTableVisible(false)}
                              color="#000"
                              className="cursor-pointer text-2xl"
                            />
                          </div>
                          <div className="w-full h-px bg-slate-500 mt-1 mb-5" />
                        </div>

                        <table className="w-full text-sm text-left text-gray-500">
                          <thead
                            className="text-xs text-gray-700 uppercase
        border-b border-gray-200"
                          >
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 bg-gray-50 text-center"
                              >
                                Date
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                Amount
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 bg-gray-50 text-center"
                              >
                                transactionId
                              </th>
                              <th scope="col" className="px-6 py-3 text-center">
                                status
                              </th>
                            </tr>
                          </thead>
                          {creditTableArray?.map((item, index) => {
                            return (
                              <tbody key={index}>
                                <tr className="border-b border-gray-200">
                                  <th
                                    scope="row"
                                    className="px-5 py-3 text-center font-medium text-gray-900 
                                    whitespace-nowrap bg-gray-50"
                                  >
                                    {moment(new Date(item?.date)).format(
                                      "DD-MM-YYYY hh:mm a"
                                    )}
                                  </th>
                                  <td className="px-5 py-3 text-center">
                                    {item?.amount}
                                  </td>
                                  <td className="px-5 py-3 text-center bg-gray-50">
                                    {item?.transactionId}
                                  </td>
                                  <td
                                    className={`px-5 py-3 text-center ${
                                      item?.transactionIdVerification ===
                                      "success"
                                        ? "text-green-500 font-medium"
                                        : item?.transactionIdVerification ===
                                          "failed"
                                        ? "text-red-500 font-medium"
                                        : "font-medium"
                                    }`}
                                  >
                                    {item?.transactionIdVerification}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table> */}

                        <div className="flex items-center justify-end mr-5 mt-3">
                          <BiSkipPrevious
                            onClick={() =>
                              previousPage === null
                                ? null
                                : setPage(previousPage)
                            }
                            size={30}
                            color={previousPage === null ? "#ccc" : "#000"}
                            className={`${previousPage === null
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                              }`}
                          />
                          <h1>
                            {page} of {totalPages}
                          </h1>
                          <BiSkipNext
                            onClick={() =>
                              nextPage === null ? null : setPage(nextPage)
                            }
                            size={30}
                            color={nextPage === null ? "#ccc" : "#000"}
                            className={`${nextPage === null
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                              }`}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <h1
                      className="flex  xs:text-xs justify-center
                      ps:text-sm md:text-lg font-sans font-bold
                      text-gray-800  "
                    >
                      No Data
                    </h1>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default CreditTable;
