import React, { useState } from "react";
import { useFormik } from "formik";
import validate from "../Validation/ValidateSignUp";
import { signup, confirmSignUP, authLogin } from "../../lib/api/auth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import OtpInput from "../VerifyOTP/OTPInput";
import { getUserByEmail } from "../../lib/api/user";
import { useAuth } from "../../context/AuthContext";

function SignUpForm({ changeLayoutType, invitationCode }) {
  const { updateAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [view, setView] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);

  const [loadingOTP, setLoadingOTP] = useState(false);
  const [errorOTP, setErrorOTP] = useState(undefined);
  const [otpCode, setOtpCode] = useState("");
  const isOtpFilled = otpCode.length === 6;

  const formik = useFormik({
    initialValues: {
      email: "",
      number: "0123456789",
      password: ""
    },
    validate,
    onSubmit: (values) => {
      onSubmit(values.email, values.number, values.password);
    }
  });

  //console.log(invitationCode)

  //onSubmit
  const onSubmit = async (email, number, password) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await signup({
        email: email,
        number: number,
        password: password,
        reference: invitationCode
      });
      setError(undefined);
      setLoading(false);
      setViewEmail(true);
    } catch (e) {
      setError(e.message || JSON.stringify(e));
      setLoading(false);
    }
  };

  //ConfirmOTP
  const confirmOTP = async (email, otp, password) => {
    if (!isOtpFilled) {
      alert("fill 6 otp");
    } else {
      if (loadingOTP) {
        return;
      }
      setLoadingOTP(true);

      if (typeof email !== "string") {
        return;
      }
      try {
        await confirmSignUP({ email, emailToken: otp });
        setErrorOTP(undefined);
        const resAuth = await authLogin({ email: email, password: password });
        getUserByEmail(resAuth.authToken, email).then(async (res) => {
          await updateAuthToken(resAuth.authToken, res?.id);
          setLoadingOTP(false);
        });
      } catch (e) {
        // console.log(e);
        setErrorOTP(e.message || JSON.stringify(e));
        setLoadingOTP(false);
      }
    }
  };

  return (
    <div
      className="select-none px-9 flex 
    items-center justify-center"
    >
      <div
        className="w-full bg-white rounded-lg mt-5
       sm:max-w-md xl:p-0"
      >
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            className="text-xl font-bold my-4 text-center
           leading-tight tracking-tight text-gray-900
            md:text-2xl"
          >
            Register Account
          </h1>
          <form
            className="space-y-3 md:space-y-5"
            action="#"
            onSubmit={formik.handleSubmit}
          >
            <div>
              <label
                for="email"
                className="block mb-2 font-medium
                 text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={` border
                   text-gray-900
                  sm:text-sm rounded-lg 
                    block w-full p-2.5
                    ${
                      formik.touched.email && formik.errors.email
                        ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                        : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                    }  `}
                placeholder="abc@gmail.com"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1">{formik.errors.email}</p>
              ) : null}
              {viewEmail ? (
                <p className="text-base text-center mt-3 text-green-500 animate-bounce">
                  Check your email!
                </p>
              ) : (
                <p className="text-base text-center mt-3 animate-bounce">
                  Provide proper Email to verify your account!
                </p>
              )}
            </div>
            {/*<div>
              <label
                for="number"
                className="block mb-2 font-medium
                 text-gray-900"
              >
                Phone Number
              </label>
              <input
                type="number"
                id="number"
                name="number"
                className={` border
                   text-gray-900
                  sm:text-sm rounded-lg 
                    block w-full p-2.5
                    ${formik.touched.number && formik.errors.number
                    ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                    : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                  }  `}
                placeholder="10 digit"
                {...formik.getFieldProps("number")}
              />
              {formik.touched.number && formik.errors.number ? (
                <p className="text-xs mt-1">{formik.errors.number}</p>
              ) : null}
            </div>*/}
            {!viewEmail && (
              <>
                <div>
                  <label
                    for="password"
                    className="block mb-2 font-medium
                 text-gray-900"
                  >
                    Password
                  </label>
                  <div
                    className={`border
                   text-gray-900
                  sm:text-sm rounded-lg 
                  flex flex-row justify-between items-center pr-2
                    ${
                      formik.touched.password && formik.errors.password
                        ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                        : "bg-gray-100 text-gray-600 focus:outline-gray-300 "
                    }`}
                  >
                    <input
                      type={view ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder={view ? "password" : "********"}
                      {...formik.getFieldProps("password")}
                      className={`p-2.5 flex-grow
                 sm:text-sm rounded-lg  
                   bg-gray-100 text-gray-600 focus:outline-none
                   ${
                     formik.touched.password && formik.errors.password
                       ? "bg-red-100 text-red-600 placeholder:text-red-600"
                       : "bg-gray-100 text-gray-600 "
                   }`}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => setView(!view)}
                    >
                      {view ? (
                        <AiFillEyeInvisible size={20} color="#000" />
                      ) : (
                        <AiFillEye size={20} color="#000" />
                      )}
                    </div>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-xs mt-1">{formik.errors.password}</p>
                  ) : null}
                </div>
                {invitationCode && (
                  <div>
                    <label
                      for="invitationCode"
                      className="block mb-2 font-medium
                 text-gray-900"
                    >
                      Invitation Code
                    </label>
                    <input
                      type="invitationCode"
                      id="invitationCode"
                      name="invitationCode"
                      className={` border
                  sm:text-sm rounded-lg 
                  block w-full p-2.5 bg-gray-100
                  text-black focus:outline-gray-300`}
                      value={"Member-" + invitationCode}
                      disabled
                    />
                  </div>
                )}
                {error !== undefined && (
                  <h1
                    className="cursor-pointer w-full 
                  text-center text-sm text-red-600"
                  >
                    {error}
                  </h1>
                )}
                <div>
                  <button
                    type="submit"
                    className="w-full text-center py-2 rounded-md bg-green-600
                           active:bg-green-700 bgGreen font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-1"
                  >
                    {loading ? "Loading..." : "Get OTP"}
                  </button>
                </div>
              </>
            )}
            {viewEmail && (
              // <div
              //   className="w-full text-center py-2 rounded-md  font-medium animate-pulse
              //           text-white focus:outline-none my-1 cursor-pointer bg-green-600"
              //   onClick={() => {
              //     const email = "noreply.multigameserver@gmail.com";
              //     const subject = "New User Conformation";
              //     const body = `This is to avoid scammers! ${formik.values.email} Once we received this email will check your Id and we will deposit the amount.`;
              //     const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
              //       subject
              //     )}&body=${encodeURIComponent(body)}`;
              //     window.location = mailtoLink;
              //   }}
              // >
              //   <p className="animate-bounce">🎁Tap here to get ₹100🎁</p>
              // </div>

              <div>
                <OtpInput otpCode={otpCode} setOtpCode={setOtpCode} />
                {errorOTP !== undefined && (
                  <h1
                    className="cursor-pointer w-full 
                  text-center text-sm text-red-600"
                  >
                    {errorOTP}
                  </h1>
                )}
                <div>
                  <button
                    type="submit"
                    onClick={() =>
                      confirmOTP(
                        formik.values.email,
                        otpCode,
                        formik.values.password
                      )
                    }
                    className="w-full text-center py-2 rounded-md bg-green-600
                           active:bg-green-700 bgGreen font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-3"
                  >
                    {loadingOTP ? "Loading..." : "Register"}
                  </button>
                </div>
              </div>
            )}

            <div className="flex flex-row justify-center items-center space-x-2">
              <p className="text-center text-gray-600 my-1">Back to Login ?</p>
              <p
                className="font-medium text-center text-black my-1 cursor-pointer"
                onClick={() => changeLayoutType("Login")}
              >
                Click here.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;
