import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// import DashboardHeader from "../components/header/DashboardHeader";
// import DashboardScreen from "../screen/dashboard/DashboardScreen";
// import ProfileScreen from "../screen/profile/ProfileScreen";
import PaymentScreen from "../screen/payment/payment";
import "../assets/css/styles.css";
import { useState } from "react";
import { TbHomeShield } from "react-icons/tb";
import { GiWallet } from "react-icons/gi";
import { FaRegUser } from "react-icons/fa";
import HomeScreen from "./HomeScreen";
import WalletScreen from "./WalletScreen";
import AccountScreen from "./AccountScreen";

// const DashBoard = ({ dashBoardTable, userBettingHistory, setPage }) => {
//   return (
//     <DashboardScreen
//       dashBoardTable={dashBoardTable}
//       userBettingHistory={userBettingHistory}
//       setPage={setPage}
//     />
//   );
// };

// const Profile = ({ userDetail, setUserDetail }) => {
//   return (
//     <ProfileScreen userDetail={userDetail} setUserDetail={setUserDetail} />
//   );
// };

const Payment = ({ userDetail, setUserDetail }) => {
  return (
    <PaymentScreen userDetail={userDetail} setUserDetail={setUserDetail} />
  );
};

function RouterNavigation() {
  const [dashBoardTable, setDashBoardTable] = useState(undefined);
  const [dashBoardTableColor, setDashBoardTableColor] = useState(undefined);
  const [userBettingHistory, setUserBettingHistory] = useState(undefined);
  const [userDetail, setUserDetail] = useState(undefined);
  const [page, setPage] = useState(1);
  const [pageColor, setPageColor] = useState(1);
  const [screen, setScreen] = useState("Home");
  const [tab, setTab] = useState("color");
  const [topTab, setTopTab] = useState("One");
  const [loading, setLoading] = useState(true);

  return (
    <div className="bg-white md:h-screen">
      <Router>
        <div>
          <Switch>
            <Route exact path="/payment" render={() => <div></div>} />
          </Switch>
        </div>
        <div className="pb-20">
          <AnimatePresence exitBeforeEnter>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <div className="pb-20">
                    <AnimatePresence exitBeforeEnter>
                      {screen === "Wallet" ? (
                        <WalletScreen
                          userDetail={userDetail}
                          setUserDetail={setUserDetail}
                        />
                      ) : screen === "Account" ? (
                        <AccountScreen
                          page={page}
                          pageColor={pageColor}
                          setDashBoardTable={setDashBoardTable}
                          setDashBoardTableColor={setDashBoardTableColor}
                          setUserBettingHistory={setUserBettingHistory}
                          userDetail={userDetail}
                          setUserDetail={setUserDetail}
                          tab={tab}
                          setTab={setTab}
                          setScreen={setScreen}
                          topTab={topTab}
                          setTopTab={setTopTab}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      ) : (
                        <HomeScreen
                          page={page}
                          pageColor={pageColor}
                          setDashBoardTable={setDashBoardTable}
                          setDashBoardTableColor={setDashBoardTableColor}
                          setUserBettingHistory={setUserBettingHistory}
                          userDetail={userDetail}
                          setUserDetail={setUserDetail}
                          dashBoardTable={dashBoardTable}
                          dashBoardTableColor={dashBoardTableColor}
                          userBettingHistory={userBettingHistory}
                          setPage={setPage}
                          setPageColor={setPageColor}
                          tab={tab}
                          setTab={setTab}
                          setScreen={setScreen}
                          topTab={topTab}
                          setTopTab={setTopTab}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                )}
              />
              <Route
                exact
                path="/payment"
                render={() => (
                  <Payment
                    userDetail={userDetail}
                    setUserDetail={setUserDetail}
                  />
                )}
              />
            </Switch>
          </AnimatePresence>
        </div>
        <div class="fixed bottom-0 w-full">
          <div class="flex justify-evenly py-2 nav-bar bg-white mx-0">
            <div
              className={`flex flex-col cursor-pointer
            ${
              screen === "Home" ? "text-blue-500" : "text-gray-400"
            } items-center justify-center`}
              onClick={() => setScreen("Home")}
            >
              <TbHomeShield size={25} />
              <p className="text-xs">Home</p>
            </div>
            <div
              className={`flex flex-col cursor-pointer
            ${
              screen === "Wallet" ? "text-blue-500" : "text-gray-400"
            } items-center justify-center`}
              onClick={() => setScreen("Wallet")}
            >
              <GiWallet size={25} />
              <p className="text-xs">Wallet</p>
            </div>
            <div
              className={`flex flex-col cursor-pointer
            ${
              screen === "Account" ? "text-blue-500" : "text-gray-400"
            } items-center justify-center`}
              onClick={() => setScreen("Account")}
            >
              <FaRegUser size={25} />
              <p className="text-xs">Account</p>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default RouterNavigation;
