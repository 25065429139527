/* eslint-disable jsx-a11y/no-redundant-roles */
import moment from "moment";
import DashboardTable from "../../components/tables/DashboardTable";
import DashboardTableColor from "../../components/tables/DashboardTableColor";
import UserBettingTable from "../../components/tables/UserBettingTable";
import { useState } from "react";
import { getUserBettingTable } from "../../lib/api/userBetting";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { HiTrophy } from "react-icons/hi2";

export default function DashboardScreen({
  tab,
  dashBoardTable,
  dashBoardTableColor,
  userBettingHistory,
  setPage,
  setPageColor
}) {
  const { authToken, userId } = useAuth();
  const [userBettingViewVisible, setUserBettingViewVisible] = useState(false);
  const [userBettingHistoryView, setUserBettingHistoryView] =
    useState(undefined);
  const [pageU, setPageU] = useState(1);

  const getUserBettingTableDetails = (pageNo, pageSize) => {
    getUserBettingTable(authToken, userId, pageNo, pageSize)
      .then((res) => {
        setUserBettingHistoryView(res);
      })
      // .catch((e) => console.log(e));
  };

  useEffect(() => {
    getUserBettingTableDetails(1, 5);
  }, []);

  useEffect(() => {
    getUserBettingTableDetails(pageU, 5);
  }, [pageU]);

  return (
    <>
      {dashBoardTable?.data?.length > 0 && (
        <div className="min-h-full">
          <main>
            <div
              className="mx-auto max-w-7xl sm:px-6 lg:px-8 
          flex flex-col md:flex-row flex-1 md:justify-between
          space-y-4 md:space-x-3 md:space-y-2"
            >
              <div className="flex-1">
                <div className="flex justify-center items-center my-5">

                  <HiTrophy className="text-black" />
                  <p className="font-medium text-lg text-gray-900 ml-2">Result's Records</p>


                </div>
                {tab === "dice" ?
                  <DashboardTable
                    dashBoardTableArr={dashBoardTable?.data}
                    nextPage={dashBoardTable?.nextPage}
                    previousPage={dashBoardTable?.previousPage}
                    page={dashBoardTable?.page}
                    totalPages={dashBoardTable?.totalPages}
                    setPage={setPage}
                  />
                  :
                  <DashboardTableColor
                    dashBoardTableArr={dashBoardTableColor?.data}
                    nextPage={dashBoardTableColor?.nextPage}
                    previousPage={dashBoardTableColor?.previousPage}
                    page={dashBoardTableColor?.page}
                    totalPages={dashBoardTableColor?.totalPages}
                    setPage={setPageColor}
                  />}
              </div>

              <div
                className="w-full max-w-none p-4 bg-white border
             border-gray-200 rounded-lg shadow sm:p-8 flex-1"
              >
                <div className="flex items-center justify-between mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900">
                    Your Prediction's
                  </h5>
                  <p
                    className="text-sm font-medium cursor-pointer
                 text-blue-600 hover:underline"
                    onClick={() => setUserBettingViewVisible(true)}
                  >
                    View all
                  </p>
                </div>
                <div className="flow-root">
                  <ul
                    role="list"
                    className="divide-gray-200"
                  >
                    {userBettingHistory?.length === 0 ? (
                      <p>empty</p>
                    ) : (
                      userBettingHistory?.map((item, index) => {
                        return (
                          <li
                            className={`py-3 px-2 my-1 rounded sm:py-4 border-2 ${item.final === "win"
                              ? "bg-green-50"
                              : item.final === "lose"
                                ? "bg-red-50"
                                : "bg-gray-50"
                              }
                            ${item.amountType === "Bonus" ? "border-yellow-300" : "border-black"}`}
                            key={index}
                          >
                            <div className="flex items-center space-x-4">
                              <div className="flex-1 min-w-0">
                                <p
                                  className="text-sm font-medium text-gray-900 truncate"
                                >
                                  Period :{" "}
                                  {item?.bettingItemTableDataBaseId}
                                  {" - "}
                                  {moment().format("hh:mm a")}
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                  Guess :{" "}
                                  {item.eventHit === ""
                                    ? item.numberHit
                                    : item.numberHit === 0
                                      ? item.eventHit
                                      : ""}{" "}
                                  - Amount : {item.amount}
                                </p>
                              </div>
                              <div
                                className={`inline-flex items-center text-base
                       font-medium ${item.final === "win"
                                    ? "text-green-500"
                                    : item.final === "lose"
                                      ? "text-red-500"
                                      : "text-blue-600"
                                  }`}
                              >
                                {item.finalAmount
                                  ? item.finalAmount
                                  : "waiting..."}
                              </div>
                            </div>
                          </li>
                        );
                      })
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
      <UserBettingTable
        userBettingViewVisible={userBettingViewVisible}
        setUserBettingViewVisible={setUserBettingViewVisible}
        userBettingHistoryView={userBettingHistoryView?.data}
        nextPage={userBettingHistoryView?.nextPage}
        previousPage={userBettingHistoryView?.previousPage}
        page={userBettingHistoryView?.page}
        totalPages={userBettingHistoryView?.totalPages}
        setPage={setPageU}
      />
    </>
  );
}
