import React from "react";
import "./loadingdice.css";

function DiceRoll() {
  return (
    <div>
      <div id="platforml" className="playingl">
        <div id="dicel">
          <div className="sidel frontl">
            <div className="dotl centerl"></div>
          </div>
          <div className="sidel frontl innerl"></div>
          <div className="sidel topl">
            <div className="dotl dtopl dleftl"></div>
            <div className="dotl dbottoml drightl"></div>
          </div>
          <div className="sidel topl innerl"></div>
          <div className="sidel rightl">
            <div className="dotl dtopl dleftl"></div>
            <div className="dotl centerl"></div>
            <div className="dotl dbottoml drightl"></div>
          </div>
          <div className="sidel rightl innerl"></div>
          <div className="sidel leftl">
            <div className="dotl dtopl dleftl"></div>
            <div className="dotl dtopl drightl"></div>
            <div className="dotl dbottoml dleftl"></div>
            <div className="dotl dbottoml drightl"></div>
          </div>
          <div className="sidel leftl innerl"></div>
          <div className="sidel bottoml">
            <div className="dotl centerl"></div>
            <div className="dotl dtopl dleftl"></div>
            <div className="dotl dtopl drightl"></div>
            <div className="dotl dbottoml dleftl"></div>
            <div className="dotl dbottoml drightl"></div>
          </div>
          <div className="sidel bottoml innerl"></div>
          <div className="sidel backl">
            <div className="dotl dtopl dleftl"></div>
            <div className="dotl dtopl drightl"></div>
            <div className="dotl dbottoml dleftl"></div>
            <div className="dotl dbottoml drightl"></div>
            <div className="dotl centerl dleftl"></div>
            <div className="dotl centerl drightl"></div>
          </div>
          <div className="sidel backl innerl"></div>
          <div className="sidel coverl xl"></div>
          <div className="sidel coverl yl"></div>
          <div className="sidel coverl zl"></div>
        </div>
      </div>
    </div>
  );
}

export default DiceRoll;
