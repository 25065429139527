import React, { useRef } from "react";

const OtpInput = ({ otpCode, setOtpCode }) => {
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const handleChange = (index, text) => {
    if (/^\d*$/.test(text) && text.length <= 1) {
      const newOtpCode =
        otpCode.slice(0, index) + text + otpCode.slice(index + 1);
      setOtpCode(newOtpCode);

      if (index < 5 && text.length === 1) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyPress = (event, index) => {
    if (event.key === "Backspace") {
      event.preventDefault();

      if (otpCode.length > 0 && index >= 0) {
        const newOtpCode = otpCode.slice(0, index) + otpCode.slice(index + 1);
        setOtpCode(newOtpCode);
        inputRefs.current[index].current.focus();
      }
    }
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="mt-4"
    >
      {[...Array(6)].map((_, index) => (
        <input
          key={index}
          type="number"
          ref={inputRefs.current[index]}
          className="font-medium rounded-md border-gray-300 bg-gray-100 text-gray-600 focus:outline-gray-300"
          style={{
            width: 40,
            height: 40,
            margin: 5,
            textAlign: "center",
            borderWidth: 0.3,
            borderRadius: 10,
          }}
          maxLength={1}
          onKeyDown={(event) => handleKeyPress(event, index)}
          onChange={(event) => handleChange(index, event.target.value)}
          value={otpCode.charAt(index) || ""}
        />
      ))}
    </div>
  );
};

export default OtpInput;
