import { API_URL } from "./config";

export const signup = async ({ email, number, password, reference }) => {
  // console.log(JSON.stringify({ email,  number, password }));
  const res = await fetch(`${API_URL}/auth/signup`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json"
    },
    body: JSON.stringify({
      email,
      number,
      password,
      reference,
      company: "82Bett"
    })
  });
  if (res.status !== 200) {
    throw new Error("Email already exits!");
  }
};

export const confirmSignUP = async ({ email, emailToken }) => {
  // console.log(JSON.stringify({ email, emailToken }));
  const res = await fetch(`${API_URL}/auth/signup/conformation`, {
    method: "PUT",
    headers: {
      "Content-type": "Application/json"
    },
    body: JSON.stringify({ email, emailToken, company: "82Bett" })
  });

  if (res.status === 401) {
    throw new Error("Wrong code!");
  } else if (res.status === 402) {
    throw new Error("Token expired!");
  } else if (res.status === 403) {
    throw new Error("Email is incorrect!");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const authLogin = async ({ email, password }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/auth/login-and-authenticate`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json"
    },
    body: JSON.stringify({ email, password })
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("User is not found");
  } else if (res.status === 401) {
    throw new Error("User is not confirmed!");
  } else if (res.status === 402) {
    throw new Error("Invalid email or password");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const getConfirmCode = async ({ email }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/auth/conformation`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json"
    },
    body: JSON.stringify({ email })
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("Something went wrong. Please try later");
  } else if (res.status === 401) {
    throw new Error("User is not found");
  } else {
    return res.json();
  }
};

export const login = async ({ email, password }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json"
    },
    body: JSON.stringify({ email, password })
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("user is not found");
  } else if (res.status === 402) {
    throw new Error("Invalid email or password");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const authenticate = async ({ email, emailToken }) => {
  const res = await fetch(`${API_URL}/auth/authenticate`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json"
    },
    body: JSON.stringify({ email, emailToken })
  });
  if (res.status !== 200) {
    throw new Error("Error during the login process");
  }
  return res.json();
};
