import React from "react";
import { MdDelete } from "react-icons/md";

function AccountDetails({ item, onDeleteAccount, viewDetails }) {
  return (
    <div
      className="flex flex-row my-3 select-none
    shadow-sm shadow-blue-100 rounded-lg border-2
    border-sky-100 xs:text-xs sm:text-base md:text-sm lg:text-base"
    >
      <div className="flex flex-grow items-center pl-3">
        <div>
          {item?.accountNumber === "" ? (
            <div className="lg:flex">
              <h1 className="flex text-sm font-medium text-gray-800">UPI</h1>
              <h1 className="flex text-sm text-gray-600 ml-1">
                {item?.upiAddress}
              </h1>
            </div>
          ) : (
            <div className="py-2">
              <div className="lg:flex">
                <h1 className="flex text-sm font-medium text-gray-800">
                  AccountNumber
                </h1>
                <h1 className="flex text-sm text-gray-600 ml-1">
                  {
                    viewDetails ?
                      item?.accountNumber :
                      item?.accountNumber?.slice(0, 2) + "******" + item?.accountNumber?.slice(12, 14)
                  }
                </h1>
              </div>
              <div className="lg:flex pt-1">
                <h1 className="flex text-sm font-medium text-gray-800">IFSC</h1>
                <h1 className="flex text-sm text-gray-600 ml-1">
                  {
                    viewDetails ?
                      item?.ifscCode :
                      item?.ifscCode?.slice(0, 2) + "******" + item?.ifscCode?.slice(9, 11)
                  }
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="rounded-r-lg flex items-end py-5 pr-5"
        onClick={() => onDeleteAccount()}
      >
        <MdDelete className="text-red-700 text-2xl cursor-pointer" />
      </div>
    </div>
  );
}

export default AccountDetails;
