import { API_URL } from "./config";

export const postOrder = async (authToken, data) => {
  // console.log(authToken, JSON.stringify(data));

  // await fetch(`${API_URL}/order`, {
  //   method: "POST",
  //   body: JSON.stringify({
  //     amount,
  //     currency,
  //     receipt: receiptId,
  //   }),
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });
  const res = await fetch(`${API_URL}/order`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Please try after some time");
  }
  return await res.json();
};

export const postValidateOrder = async (authToken, data) => {
  // console.log(authToken, JSON.stringify(data));
  // await fetch(
  //   `${API_URL}/order/validate`,
  //   {
  //     method: "POST",
  //     body: JSON.stringify(body),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }
  // );
  const res = await fetch(`${API_URL}/order/validate`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Please try after some time");
  }
  return await res.json();
};
