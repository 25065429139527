import React from "react";
import Timer from "./Timer";
import { HiTrophy } from "react-icons/hi2";

function ResultHeader({
  serverTimer,
  isDisabled,
  setServerLoading,
  setIsDisabled,
  userDetail,
  lastItemId,
}) {
  return (
    // <div
    //   className="pt-5 px-4 sm:px-6 lg:px-8
    //    flex justify-between items-center bg-gradient-to-r from-cyan-400 to-blue-400"
    // >
    <div
      className="pt-5 px-4 sm:px-6 lg:px-8
       flex justify-between items-center bg-gray-100"
    >
      <div className="flex flex-col items-start justify-center">
        <div className="flex items-start space-x-1">
          <HiTrophy className="text-black" />
          <p className="font-medium text-sm text-black">Period</p>
        </div>
        <h1 className="text-xl tracking-wide font-bold text-black">
          {lastItemId ? lastItemId : <p className="text-xs">Loading....</p>}
        </h1>
      </div>

      <Timer
        serverTimer={parseInt(serverTimer)}
        setServerLoading={setServerLoading}
        setIsDisabled={setIsDisabled}
        userDetail={userDetail}
      />
    </div>
  );
}

export default ResultHeader;
