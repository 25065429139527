// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { AnimatePresence } from "framer-motion";
// import "../assets/css/styles.css";
// import { useState } from "react";
// import { TbHomeShield } from "react-icons/tb";
// import { GiWallet } from "react-icons/gi";
// import { FaRegUser } from "react-icons/fa";
// import HomeScreen from "./HomeScreen";
// import WalletScreen from "./WalletScreen";
// import AccountScreen from "./AccountScreen";

// function RouterNavigation({ invitationCode }) {
//   const [screen, setScreen] = useState("Home");

//   return (
//     <div className="bg-white md:h-screen">
//       <Router>
//         <div>
//           <Switch>
//             <Route exact path="/payment" render={() => <div></div>} />
//           </Switch>
//         </div>
//         <div className="pb-20">
//           <AnimatePresence exitBeforeEnter>
//             <Switch>
//               <Route
//                 exact
//                 path="/"
//                 render={() => (
//                   <div className="pb-20">
//                     <AnimatePresence exitBeforeEnter>
//                       {
//                         screen === "Wallet" ?
//                           <WalletScreen
//                             invitationCode={invitationCode}
//                           />
//                           : screen === "Account" ?
//                             <AccountScreen
//                               invitationCode={invitationCode}
//                             />
//                             : <HomeScreen
//                               invitationCode={invitationCode}
//                             />
//                       }
//                     </AnimatePresence>
//                   </div>
//                 )}
//               />
//             </Switch>
//           </AnimatePresence>
//         </div>
//         <div class="fixed bottom-0 w-full">
//           <div class="flex justify-evenly py-2 nav-bar bg-white mx-0">
//             <div className={`flex flex-col cursor-pointer
//             ${screen === "Home" ? "text-blue-500" : "text-gray-400 shadow"}
//              items-center justify-center`}
//               onClick={() => setScreen("Home")}>
//               <TbHomeShield size={25} />
//               <p className="text-xs">Home</p>
//             </div>
//             <div className={`flex flex-col cursor-pointer
//             ${screen === "Wallet" ? "text-blue-500" : "text-gray-400"} items-center justify-center`}
//               onClick={() => setScreen("Wallet")}>
//               <GiWallet size={25} />
//               <p className="text-xs">Wallet</p>
//             </div>
//             <div className={`flex flex-col cursor-pointer
//             ${screen === "Account" ? "text-blue-500" : "text-gray-400"} items-center justify-center`}
//               onClick={() => setScreen("Account")}>
//               <FaRegUser size={25} />
//               <p className="text-xs">Account</p>
//             </div>
//           </div>
//         </div>
//       </Router>
//     </div>
//   );
// }

// export default RouterNavigation;

import React, { useState } from "react";
import LoginForm from "../form/LoginForm";
import ConfirmForm from "../form/ConfirmCode/ConfirmForm";
import SignUpForm from "../form/SignUp/SignUpForm";
// import AppAuthHeader from "../components/header/AppAuthHeader";

function RouterNavigation({ invitationCode }) {
  const [layoutType, setLayoutType] = useState("Login");
  const changeLayoutType = (type) => {
    setLayoutType(type);
  };
  return (
    <div>
      {/* <AppAuthHeader
        layoutType={layoutType}
        // changeLayoutType={changeLayoutType}
        from={"Account"}
      /> */}
      {layoutType === "Login" ? (
        <LoginForm changeLayoutType={changeLayoutType} />
      ) : layoutType === "ConfirmUser" ? (
        <ConfirmForm changeLayoutType={changeLayoutType} />
      ) : (
        <>
          <SignUpForm
            changeLayoutType={changeLayoutType}
            invitationCode={invitationCode}
          />
        </>
      )}
    </div>
  );
}

export default RouterNavigation;
